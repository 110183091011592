/* Variables */
@import url("bulma/css/bulma.css");
@import url("material-design-icons/iconfont/material-icons.css");
:root {
  --primaryColor: #042c77;
  --red: #e61a1a;
  --green: #5ecd4c;
  --grey: #0000005c;
  --white: #ffffff;
  --secondaryGreen: #4caf50;
  --roboto: "Roboto", sans-serif;
  --actionMailsMargin: 30px;
}

/*SideBar */
.sidebar {
  height: 90vh;
  position: relative;
  min-width: 0 !important;
  width: 150px !important;
  background-color: white;
  font-family: var(--roboto);
}

.sidebar-menu {
  height: 100%;
  border-right: 0;
  width: 150px;
  min-width: none;
}

/* Topbar */
.topbar {
  background-color: var(--primaryColor);
  width: 100%;
  position: relative;
  height: max-content;
}

.content {
  position: relative;
  background: white !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.logo-img {
  max-height: 20px;
  max-width: 20px;
}

.left-icons {
  float: right !important;
  display: flex !important;
  padding-top: 10px;
  padding-bottom: 0px;
}

.profile-image {
  border-radius: 50%;
  max-height: 40px;
  max-width: 30px;
}

.profile-image:hover {
  cursor: pointer;
}

.changeCursor:hover {
  cursor: text;
  text-decoration: none;
}

.menu-divider {
  margin: 0;
  border: 0.5px solid gray;
}

.topbar-notification {
  width: 300%;
  padding-left: 20px;
  padding-right: 20px;
}

.topbar-notification-title {
}

.topbar-notification-read {
  margin-left: 20px;
}

.topbar-notification-read:hover {
  cursor: pointer;
}

/* Actions Page  */

.actions {
  font-family: var(--roboto);
}

.actions-mails-card {
  width: 100%;
}

.actions-mail-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.actions-mails-title {
  font-size: 18px;
  vertical-align: middle;
  color: var(--primaryColor);
}

.actions-circle-number {
  width: 140px;
  height: 100px;
  background: var(--red);
  border-radius: 50%;
  margin-left: 10px;
  font-size: 16px;
  padding: 1px 4px 1px 1px;
  vertical-align: middle;
  color: var(--white);
  font-weight: 500;
}

.actions-candidates-text {
  font-size: 12px;
  color: var(--grey);
  margin-left: var(--actionMailsMargin);
  line-height: 14.06px;
}

.actions-grid-card {
  width: 60%;
  border-radius: 10px;
}

.actions-grid-position {
  line-height: 16px;
  font-style: normal;
}

.actions-positions-checkbox {
  margin-right: 16px;
}

.actions-grid-position-name {
  color: var(--primaryColor);
  font-weight: 400;
  font-size: 14px;
}

.actions-grid-position-mails {
  color: var(--red);
  font-weight: 500;
  margin-left: 22px;
}
.action-grid-empty-positions {
  margin-left: var(--actionMailsMargin);
  color: var(--red);
  font-weight: 500;
  font-size: 16px;
}

.actions-candidates-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.actions-candidates-title {
  font-size: 18px;
  vertical-align: middle;
  color: var(--primaryColor);
  line-height: 16px;
}

.actions-candidate-name {
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: var(--primaryColor);
  top: 10px;
}

.actions-candidate-title {
  color: var(--red);
  font-weight: 500;
}

.actions-emails-text {
  color: var(--grey);
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.actions-candidates-preview {
  display: flex;
  justify-content: right;
  vertical-align: middle;
  line-height: 14px;
}

.actions-candidates-preview:hover {
  cursor: pointer;
}

.actions-candidate-created {
  margin-left: var(--actionMailsMargin);
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  color: var(--grey);
}

/*  Notifications   */
.notifications-list {
  height: 400px;
  width: 400px;
  z-index: 99999;
  overflow: auto;
  background-color: var(--white);
  border: 1px solid rgba(140, 140, 140, 0.35);
}

.notification-seen-false {
  background-color: rgba(70, 84, 167, 0.05);
  padding: 1px 16px;
}

.notification-seen-true {
  background-color: var(--white);
  padding: 1px 16px;
}

.notifications-email-icon {
  fill: var(--primaryColor);
}

.notfications-candidate-icon {
  fill: var(--green);
}

/* Candidate Page */
.dot {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
}
/* .candidates-table {
  margin-left: 20px;
  width: 95%;
} */

.pointer-hover:hover {
  cursor: pointer;
}

.candidates-button-add {
  color: #ffffff;
  background-color: var(--primaryColor);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.candidates-action-menu {
  width: 150px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--roboto);
}

.candidates-dropdown-button {
  fill: black;
}

.candidates-dropdown-button:hover {
  cursor: pointer;
  fill: var(--primaryColor);
}

/* .candidates-top-page {
  display: inline-block;
} */
/* .candidates-search-bar {
  display: inline;
  width: 80%;
} */

/*  Positions */

.positions-left-column {
  border-width: 2px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  border-radius: 4px;
}
.postion-name-row {
  margin-left: 16px;
  margin-top: 24px;
  vertical-align: middle;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.positions-add-button {
  background-color: var(--primaryColor);
  color: var(--white);
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 500;
  height: 36px;
  width: 145px;
  margin-top: 24px;
  margin-right: 16px;
  vertical-align: middle;
}

.positions-number {
  color: rgba(0, 0, 0, 0.54);
  margin-left: 16px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
}

.positions-position-name {
  color: var(--primaryColor);
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.positions-position-location {
  margin-left: 11px;
  color: rgba(0, 0, 0, 0.54);
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  vertical-align: middle;
}

.positions-location-icon {
  margin-top: 11px;
  vertical-align: middle;
}

.positions-position-date {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 28px;
  margin-bottom: 10px;
}

.positions-position-row-clicked {
  background-color: rgba(70, 84, 167, 0.05);
}

.positions-position-row:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.12);
}

.positions-right-column {
  background-color: var(--white);
}

/* Current position */
.positions-current-position-name {
  font-family: var(--roboto);
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
}

.positions-current-position-icon {
  vertical-align: middle;
}

.positions-current-position-icon {
  vertical-align: middle;
}
.positions-current-position-location {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 16px;
}

.positions-current-position-date {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 57px;
}

.position-switch {
  margin: 5px;
  font-family: var(--roboto);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.position-description-save {
  background-color: var(--primaryColor);
  color: var(--white);
  margin-top: 1px;
  margin-bottom: 6px;
}

.position-description-edit {
  margin-left: 20px;
  vertical-align: middle;
}

.position-description-edit:hover {
  cursor: pointer;
}

.position-description-title {
  color: var(--primaryColor);
  font-family: var(--roboto);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.company-fields-row {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  color: var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.admin-generalInfo {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #042c77;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.ant-modal-content {
  border-radius: 4px !important;
  background-color: white;
}

.ant-modal-header {
  border-radius: 4px !important;
}

.modal-title {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #042c77;
}

.ant-modal-footer > .ant-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  height: 42px;
  width: 92px;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  /* color: inherit; */
  /* background-color: inherit; */
}

.ant-btn-primary {
  background-color: #042c77;
}

.ant-btn-primary:hover {
  background-color: #092d85;
}

.ant-modal-footer {
  padding: 0px 24px 10px 0px;
  text-align: end;
  border: 0px;
}

.candidate-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #042c77;
}

.candidate-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.button-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: #ffffff;
}

.document-card-style {
  background: rgba(70, 84, 167, 0.05);
  border-radius: 4px;
}

.document-card-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #042c77;
}
.document-card-type {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.54);
}

.date-grey {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.text.black {
  color: rgba(0, 0, 0, 0.87);
}

.default-cursor{
  cursor : pointer
}
